/* FONT IMPORTS */
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Montserrat+Alternates:300,400,600,700&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,700,800&display=swap');

/* THEME COLORS */
$colors: (primary: #00d5ff,
    secondary: #a7a8aa,
    terniary: #1870b4,
    subtitle-color: #daaf5c,
    text-darker: #333333,
    text-dark: #454a4d,
    text-light: #888888,
    text-lighter: #fff,
    placeholder_text: #b5d1ee,
    black-clr: #000000,
    white-clr: #ffffff,
    dark: #475966,
    dark_bg: #36434d,
    background_main: #ffffff,
    background_alt: #f6f6f6,
    nav_color: #555555,
    nav_color_accent: #777777,
    nav_bg_color: #fff,
    nav_bg_color_accent: #f2f2f2,
    footer_bg: #17191a,
    footer_bg_accent: #17191a,
    footer_border: #2f3031,
    footer_color: #cccccc,
    footer_color_accent: #8b8c91,
    footer_social_icons_color: #2f363d,
    border_dark: #6f757b,
    border_darker: #5ec6e0,
    border_light: #ccc,
    border_lighter: #e5e5e5,
);

@function color($key) {
    @if map-has-key($colors, $key) {
        @return map-get($colors, $key);
    }

    @warn "Unknown `#{$key}` in $colors.";
    @return null;
}

/* FONTS */
$fonts: (Montserrat: ('Montserrat', sans-serif),
    Poppins: ('Poppins', sans-serif),
    main: ('Montserrat', sans-serif),
    sub: ('Poppins', sans-serif),
    roboto: ('Roboto', sans-serif),
);

@function font($key) {
    @if map-has-key($fonts, $key) {
        @return map-get($fonts, $key);
    }

    @warn "Unknown `#{$key}` in $fonts.";
    @return null;
}

/* CSS VARIABLES (BOOTSTRAP OVERRIDES) */
:root {
    /* BOOTSTRAP ROOT VARS
// Uncomment to change vars
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #ffffff;
    --gray: #868e96;
    --gray-dark: #343a40;
*/
    --primary: #c90000;
    --secondary: #adc400;
    --terniary: #1870b4;
    /*  
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
*/
    --dark: #475966;
    /*  
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
*/
}

/* MEDIA QUERY BREAKPOINTS */
$media_xs: 420px;
$media_sm: 576px;
$media_md: 768px;
$media_lg: 992px;
$media_xl: 1200px;
$media_xxl: 1400px;

/* Border variables */
$border_radius: 3px;

/* GLOBAL HTML TAG STYLES AND RESETS */
html {
    font-size: 14px;

    body {
        background-color: color(white-clr);
        color: color(text-dark);



        font-family: font(main);
        overflow-x: hidden;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100%;

        &.menu-is-open {
            position: relative;
            /*             overflow: hidden;
            max-height: 100%; */
        }

        font-family: font(main);
    }
}

.main-header {
    color: color(white-clr);

    font-family: font(main);

    .image-layer {
        background-size: cover !important;
        height: 1060px;
        z-index: 30;
        position: relative;
        max-width: 100vw;
    }

    .header-title {
        font-weight: 200;
        font-size: 5.174rem;
        padding-top: 40vh;
        display: block;
    }

    .header-subtitle {
        font-weight: 200;
        font-size: 4.5rem;
    }

    .header-text {
        font-weight: 200;
        font-size: 1.357rem;

        p {
            font-weight: 200;
            font-size: 1.357rem;
            margin-bottom: 1rem;
        }
    }

    @media (max-width: $media_md) {
        .header-title {
            padding-top: 30vh;
            font-size: 3.174rem;
        }
    }

}

.floating-menu {
    position: fixed;
    z-index: 999;
    right: 5px;
    top: calc(50% - 157px);
    background: white;
    width: 55px;

    @include box-shadow(-11px 2px 18px -6px rgba(0, 0, 0, 0.2));


    .social-contact-container {
        a {
            display: block;
            text-align: center;
            padding: 15px 0;
            color: #595657;
            @include transition(all .3s linear);

            &:hover {
                background: color(primary);
                color: color(white-clr);
            }

            i {
                font-size: 20px;

            }
        }
    }

    .lang-wrapper {
        background: #221e1f;
        color: #e9e9e9;
        padding: 10px 5px;
        text-align: center;

        a {
            font-size: 11px;

            &:hover {
                text-decoration: none;
            }

            i {
                font-size: 13px;
            }
        }

        .dropdown-menu {
            min-width: 1px;
            color: #221e1f;
            background: #e9e9e9;
            right: 7px;

            max-width: 55px;

            @media(min-width: 769px) {
                left: unset !important;
            }

            .dropdown-item {
                color: #221e1f;

                &:hover {
                    background: #221e1f;
                    color: #e9e9e9;
                }
            }
        }
    }

    @media(max-width: $media_md) {
        top: unset;
        top: 0;
        left: 0;
        right: unset;
        width: 100%;
        display: block;
        @include box-shadow(0px 9px 18px -6px rgba(0, 0, 0, 0.2));

        .social-contact-container {
            float: left;
            display: inline-block;
            width: 75%;

            a {
                display: inline-block;
                padding: 0;
                width: 32%;
                padding: 8px 5px;
            }
        }

        .lang-wrapper {
            display: inline-block;
            // padding: unset;
            width: 25%;
            float: right;

            .dropdown-toggle {
                display: inline-block;
                width: 100%;
            }

            .dropdown-menu {
                // left: 0 !important;
                right: unset;
                max-width: unset;
                // width: 100%;
            }
        }
    }
}

.section {
    padding-top: 60px;
    padding-bottom: 60px;

    hr.section-separator {
        border: none;
        height: 3px;
        background: color(primary);
        color: color(primary);
        width: 100px;
        position: relative;
        margin-top: 30px;
        margin-bottom: 30px;

        // &:before {
        //     content: '';
        //     height: 1px;
        //     color: color(primary);
        //     background: color(primary);
        //     width: 50px;
        //     display: block;
        //     position: absolute;
        //     left: -50px;
        //     top: 1px;
        // }

        // &:after {
        //     content: '';
        //     height: 1px;
        //     color: color(primary);
        //     background: color(primary);
        //     width: 50px;
        //     display: block;
        //     position: absolute;
        //     right: -50px;
        //     top: 1px;
        // }

    }

    .section-title {
        font-family: font(main);
        font-size: 30px;
        font-weight: 700;
        color: color(text-dark);
        text-align: center;
        text-transform: uppercase;

        // &h1 {
        //     font-size: 40px;
        // }
    }

    .section-subtitle {
        font-family: font(main);
        font-weight: 700;
        font-size: 24px;
        color: color(text-dark);
        text-align: center;
        font-style: italic;
    }

    .section-text {
        font-family: font(sub);
        font-weight: 300;
        font-size: 16px;
        color: color(text-dark);
        margin-top: 20px;
        margin-bottom: 20px;
        @include page-text();

    }

    .section-btn {
        margin: 0 auto;
        color: color(white-clr) !important;
    }
}

.contact-home-section {
    padding-bottom: 0;
    padding-top: 0;

    .container-fluid {
        padding-left: 0;


        @media(max-width: $media_md) {
            padding-right: 0;

            .row {
                margin-left: unset;
                margin-right: unset;

                .contact-home-map {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }

    .contact-home-form {
        padding-top: 35px;
        padding-bottom: 45px;
        padding-right: 15px;

        @media(min-width: 769px) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        .form-title {
            font-family: font(sub);
            font-size: 21px;
            font-weight: 400;
            color: color(text-dark);
            margin-bottom: 2rem;
        }

        .form-control {
            border: none;
            border-bottom: 1px solid color(border_light);
            color: color(text-dark);
            @include border-radius(0);
            font-size: 15px;
            font-family: font(sub);
            font-weight: 300;
            height: 30px;
            padding-left: 5px;
            line-height: 30px;

            &#contact_message {
                border: 1px solid color(border_light);
            }

            &::-webkit-input-placeholder {
                /* Edge */
                color: color(text-dark);
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: color(text-dark);
            }

            &::placeholder {
                color: color(text-dark);
            }
        }

        .dm-form-wrapper textarea {
            height: 90px !important;
        }

        .checkbox {
            label {
                font-size: 14px;
                font-family: font(sub);
                font-weight: 400;
            }
        }

        .contact-btn {
            color: color(text-lighter);
            background: color(primary);
            border: 1px solid color(primary);
            padding: 10px 5px;
            font-size: 15px;
            font-family: font(sub);
            font-weight: 700;
            text-transform: uppercase;
            @include transition(all .3s linear);

            &:hover {
                color: color(primary);
                background: transparent;
            }
        }
    }
}

.contact-section {

    .contact-btn {
        background: color(primary);
        border: 2px solid color(primary);
        text-decoration: none;
        text-transform: uppercase;
        font-family: font(main);
        font-size: 1.125rem;
        font-weight: 600;
        color: color(white-clr);
        padding: 10px 20px;
        @include transition(all .3s linear);

        &:hover {
            color: color(text-darker);
            background: transparent;
            border: 2px solid color(primary);
        }
    }
}

// new end

// old

a {
    @include transition(all 0.1s ease-in);

    &:link,
    &:visited,
    &:hover,
    &:active {
        color: inherit;
        outline: none;
    }
}

ul {
    list-style: none;
    padding: 0;
    font-size: 1.3rem;
}

button {
    border: 0;
    background-color: transparent;
    color: color(text-dark);
    padding: 0;
    cursor: pointer;

    &:focus {
        outline: 0;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    margin: 0;
}

p {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
}

.red {
    color: #c90000 !important;
}

.error {
    border-color: #c90000 !important;
}

/* BOOTSTRAP OVERRIDES */
.card {
    border: 0;
    background-color: transparent;

    .card-header {
        border: 0;
        background-color: transparent;
    }

    .card-footer {
        border: 0;
        background-color: transparent;
    }
}


.text-muted {
    font-size: 1.2rem !important;
    color: color(footer_color_accent) !important;
    @include opacity(0.35);
}

/* Global TEXT styling */
.page-subtitle {
    padding-top: 7vh;
    padding-bottom: 7vh;
    color: color(primary);
}

.page-text {
    font-size: 1rem;

    p {
        font-size: 1rem;
        line-height: 1.5;
        font-family: font(sub);
    }
}


/* BACKGROUND ALTERNATE CLASS */
.bg-alt {
    background-color: color(background_alt) !important;
    color: color(dark) !important;

    .dm-button {
        &.alternate {
            color: #FFF;
        }
    }
}

/* GLOBAL LINK BUTTONS */
/* Button with primary background color */
.dm-button {
    font-size: 14px;
    font-weight: 600;
    padding: 0 12px;
    line-height: 34px;
    display: inline-block;
    background-color: color(primary);
    border: 1px solid color(primary);
    color: color(white-clr);
    border-radius: $border_radius;
    @include transition(all 0.15s ease-in-out);

    &:link {
        color: #ffffff;
    }

    &:hover {
        text-decoration: none;
        background-color: darken(color(primary), 5%);
        border-color: darken(color(primary), 5%);
    }

    /* BACKGROUND FILL ANIMATION --> remove if not using */
    &.fill {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: color(primary);
            z-index: -1;
            @include transition(top 0.1s ease-in);
        }

        &:hover {
            &:before {
                top: 100%;
            }
        }
    }

    /* ARROW ANIMATION */
    &.arrow {
        overflow: hidden;
        position: relative;

        &:after {
            content: "\f178";
            font-family: 'FontAwesome';
            opacity: 0;
            font-weight: 100;
            width: 0;
            display: inline-block;
            text-indent: 0;
            @include transition(all 250ms cubic-bezier(0.680, -0.550, 0.265, 1));
        }

        &:hover {
            &:after {
                width: 20px;
                text-indent: 8px;
                opacity: 1;
            }
        }
    }

    /* Button link */
    &.link {
        color: color(primary);
        font-weight: 400;
        padding: 0;
        border: 0;
        background-color: transparent !important;

        i {
            margin-left: 10px;
        }

        &:hover {
            i {
                -webkit-animation: leftToRight 1s ease-out infinite;
                animation: leftToRight 1s ease-out infinite;
            }
        }
    }

    /* Button with light border, text color and transparent background */
    &.outline {
        background-color: transparent !important;
        border-color: color(border_light);
        color: #ffffff;
        font-weight: 400;
    }

    /* Button with secondary background color */
    &.secondary {
        background-color: color(secondary);
        border-color: color(secondary);

        &:hover {
            background-color: darken(color(secondary), 5%);
            border-color: darken(color(secondary), 5%);
        }
    }

    /* Button with dark background color */
    &.dark {
        background-color: color(dark);
        border-color: color(dark);
        color: #ffffff;

        &:hover {
            background-color: darken(color(dark), 5%);
            border-color: darken(color(dark), 5%);
        }
    }

    &[type="submit"] {
        cursor: pointer;
    }
}

/* BACK TO TOP */
.back-to-top {
    width: 40px;
    height: 40px;
    border-radius: $border_radius;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    line-height: 40px;
    text-align: center;
    display: inline-block;
    // background-color: color(secondary);
    background: color(text-dark);
    position: fixed;
    right: 5px;
    bottom: 10px; // 20px
    transform: translate(200px, 0);
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    z-index: 30 !important;

    i {
        font-size: 18px;
        color: #ffffff;
    }

    &.animate-in {
        transform: translate(0, 0);
    }

    &.animate-out {
        transform: transalte(200px, 0);
    }
}

/* BUTTON CLOSE */
.btn-close {
    // padding: 17px 53px;
    padding-left: 10px;
    color: #ffffff;
    font-size: 2rem;
    @include opacity(0.7);
    text-transform: uppercase;

    .btn-close-icon {
        font-size: 2rem;
        border-radius: 50%;
        display: inline-block;
        // padding-right: 16px;
        // padding-left: 16px;
    }

    .btn-close-text {
        position: relative;
        top: -25%;
        left: 5%;
        line-height: 38px;
        display: inline-block;

    }

    &:hover {
        @include opacity(1);
    }

}

/* GLOBAL CONTACT CARD STYLES */
.contact-card {
    font-size: 1rem;
    font-family: font(main);
    color: color(text-dark);

    .contact-title {
        font-family: font(main);
        font-size: 1.4rem;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    i {
        margin-right: 10px;
    }

    .accent {
        font-weight: 700;
        color: color(text-dark) !important;
    }
}

/* GLOBAL IMAGE RADIO INPUT TYPE STYLES */
.dm-img-rd-wrapper {
    @include d-flex;
    flex-wrap: nowrap !important;
    justify-content: space-between;
    text-align: center;
}

.dm-img-rd {
    @include d-flex;
    @include align-items-center;
    flex-direction: column;
    margin-bottom: 3em;
    position: relative;

    [type="radio"] {
        width: 25px;
        height: 25px;
        outline: none;
        visibility: hidden;
        position: absolute;

        &:checked+label {
            &:before {
                background: color(secondary);
                border-color: color(secondary);
                box-shadow: inset 0px 0px 0px 4px #ffffff;
            }

            img {
                opacity: 1;
            }
        }
    }

    label {
        cursor: pointer;

        .label-title {
            font-size: 3.2rem;
            font-weight: 300;
        }

        &:before {
            content: "";
            display: inline-block;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 1px solid #d9d9d9;
        }
    }

    img {
        width: 100%;
        max-width: 242px;
        padding: 20px 0;
        opacity: 0.8;
        transform: opacity 0.2s;
    }
}

/* GLOBAL KEYFRAMES */
@-webkit-keyframes leftToRight {
    0% {
        transform: translateX(0);
    }

    60% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes leftToRight {
    0% {
        transform: translateX(0);
    }

    60% {
        transform: translateX(5px);
        -webkit-transform: translateX(5px);
        -moz-transform: translateX(5px);
        -ms-transform: translateX(5px);
        -o-transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
}

/* MEDIA QUERIES */
@media (max-width: $media_lg) {
    .card {
        .card-header {
            padding-right: 0;
            padding-left: 0;
        }

        .card-body {
            padding-right: 0;
            padding-left: 0;
        }
    }

    body {
        &.menu-is-open {
            overflow: hidden;
            max-height: 100%;
        }
    }
}

@media (max-width: $media_sm) {
    .dm-button {
        font-size: 1.3rem;
        line-height: 30px;
    }

    p {
        font-size: 1.7rem;
    }
}

@media(max-width: $media_xs) {
    .dm-img-rd-wrapper {
        flex-direction: column;
    }

    .dm-img-rd {
        img {
            padding: 20px 10px;
        }
    }
}

.alert {
    position: absolute;
    width: 310px;
    top: 30%;
    left: 50%;
    margin-left: -130px;
    z-index: 9999;
    font-size: 16px;
    padding: 40px;
}

.table-legend {
    position: relative;
    width: 100%;
    text-align: right;

    @media (max-width: $media_md) {
        text-align: center;
    }

    ul {
        position: relative;
        top: 100px;
        right: 20px;

        @media (max-width: $media_md) {
            right: auto;
        }

        li {
            display: inline-block;

            &:first-child {
                border-right: 1px solid #CCC;
                margin-right: 15px;
                padding-right: 15px;
            }

            >i {
                margin-right: 5px;
            }
        }
    }
}

.references-component {
    .clients-slider {
        .slick-slide {
            height: 35px;
            background-position: center center;
            background-repeat: no-repeat;
            opacity: .5;
        }
    }
}