.slider-section {
    &.slider_v4 {
        .slider-super-title {
            text-transform: none;

            h2 {
                font-weight: 300;
                font-size: 30px;

                strong {
                    font-size: 700;
                }
            }
        }

        .comment-text {
            display: inline-block;

            p {
                font-family: font(sub);
                font-weight: 200;
                font-size: 21px;
                width: auto;
                max-width: 80%;
                display: inline-block;
                // padding: 30px 0;
                color: color(text-lighter);
                line-height: 30px;

                @media(max-width: $media_md) {
                    max-width: 100%;
                    display: block;
                }
            }
        }

        .comment-quote-start {
            background-repeat: no-repeat;
            display: inline-block;
            vertical-align: top;
            height: 41px;
            width: 66px;

            @media(max-width: $media_md) {
                display: block;
                margin: 0 auto;
                vertical-align: middle;
            }
        }

        .comment-quote-end {
            background-repeat: no-repeat;
            display: inline-block;
            vertical-align: bottom;
            height: 45px;
            width: 65px;

            @media(max-width: $media_md) {
                display: block;
                margin: 0 auto;
                vertical-align: middle;
            }
        }

        .comment-author {
            margin-top: 30px;
            font-family: font(sub);
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            color: color(text-lighter);

            .author-function {
                display: block;
                font-weight: 200;
                color: color(text-lighter);
            }
        }

        .comments_slider_init {
            .navi {
                top: unset !important;
                bottom: 0 !important;
                // margin-top: -25px;
                color: color(white-clr);

                font-size: 24px;
                background: transparent !important;

                color: color(primary) !important;


                &.navi-prev {
                    left: 40% !important;
                }

                &.navi-next {
                    right: 40% !important;
                }

                @media (max-width: 1250px) {
                    &.navi-prev {
                        left: -20px !important;
                    }

                    &.navi-next {
                        right: -20px !important;
                    }
                }
            }
        }

    }
}