.section {
    &.slider-section {
        .container-fluid {
            .navi {
                &.navi-prev {
                    left: 1px;
                    color: color(white-clr);
                }

                &.navi-next {
                    right: 1px;
                    color: color(white-clr);
                }
            }

        }

        .container {
            .navi {
                &.navi-prev {
                    left: -30px;
                    color: color(white-clr);
                }

                &.navi-next {
                    right: -30px;
                    color: color(white-clr);
                }
            }

        }

        .slider_wrapper {

            .slider_item {
                .item-content {
                    .item-title {
                        font-family: font(main);
                        font-size: 16px;
                        font-weight: 600;
                        text-transform: uppercase;
                        line-height: 24px;
                        color: color(text-dark);
                        margin-bottom: 10px;
                        display: block;
                    }

                    .item-desc {
                        margin-bottom: 10px;
                        display: block;

                        p {
                            font-size: 15px;
                            font-family: font(sub);
                            font-weight: 300;
                            line-height: 24px;
                            color: color(text-dark);
                            padding-bottom: 1rem;
                        }
                    }

                    .slider-item-link {

                        color: color(primary);
                        font-size: 14px;
                        font-weight: 700;
                        font-family: font(sub);

                        i {
                            font-size: 16px;
                            color: color(primary);
                            margin-right: 5px;
                        }

                        &:hover {
                            text-decoration: none;

                            i {
                                -webkit-animation: leftToRight 1s ease-out infinite;
                                animation: leftToRight 1s ease-out infinite;
                            }
                        }

                    }
                }
            }

            .navi {
                position: absolute;
                top: 50%;
                margin-top: -25px;
                z-index: 1;
                cursor: pointer;
                font-size: 30px;
                background: color(primary);
                line-height: 30px;
                width: 30px;
                height: 30px;
                border-radius: $border_radius;
                -webkit-border-radius: $border_radius;
                -moz-border-radius: $border_radius;
                -ms-border-radius: $border_radius;
                -o-border-radius: $border_radius;

                &.navi-img {
                    height: 36px;
                    width: 20px;
                    line-height: unset;
                    margin-top: -20px;
                }

                &.slick-disabled {
                    @include opacity(0.2);
                    pointer-events: none;
                    cursor: default;
                }

                i {
                    font-size: 2.142857142857143rem;
                    font-weight: 600;
                }
            }

            .navi {
                color: #ffffff;
            }

            .slick-dots {
                position: absolute;
                bottom: -70px;
            }
        }

    }
}

@import './Slider_v1';
@import './Slider_v2';
@import './Slider_v3';
@import './Slider_v4';