.section {
  &.intro-section {
    .section-title {
      color: color(white-clr);
    }

    hr.section-separator {
      background-color: color(white-clr) !important;
    }

    .section-subtitle {
      color: color(white-clr);
    }

    .section-text {
      color: color(white-clr);
    }

    .section-btn {
      background-color: color(white-clr);
      color: color(primary) !important;

      &:hover {
        color: color(white-clr) !important;
        background-color: color(primary) !important;
      }
    }
  }
}