.section {
    &.slider_v2 {
        &.dm_counter_slider {
            .container-fluid {
                .slider_item {
                    text-align: center;
                }
            }

            .slider-title {
                color: color(white-clr);
            }

            hr.slider-separator {

                background: color(white-clr);
                color: color(white-clr);

                &:before {
                    color: color(white-clr);
                    background: color(white-clr);
                }

                &:after {
                    color: color(white-clr);
                    background: color(white-clr);
                }

            }

            .slider-subtitle {
                color: color(white-clr);
            }

            .slider-text {
                color: color(white-clr);
            }

            .slider_wrapper {
                margin-top: 25px;
            }

            .slider_item {
                @media(max-width: $media_lg) {

                    &:not(:last-of-type) {
                        margin-bottom: 20px;
                    }
                }

                @media(max-width: $media_md) {
                    text-align: center;

                }

                .counter-wrapper {
                    color: color(white-clr);
                    font-size: 27px;
                    font-family: font(main);
                    font-weight: 300;

                    .counter-value {
                        font-weight: 600;
                    }
                }

                .counter-desc {
                    color: color(white-clr);
                    font-family: font(sub);
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }

        &.dm_logo_slider {
            @media(min-width:1200px) {
                .container-fluid {
                    width: 85%;

                    .navi {
                        &.navi-prev {
                            left: -5%;

                        }

                        &.navi-next {
                            right: -5%;

                        }
                    }
                }
            }

            padding-top: 40px;
            padding-bottom: 40px;

            .slider_wrapper {
                .slick-track {
                    margin: 0 auto;
                }

                .slider_item {
                    text-align: center;
                    @include opacity(0.6);
                    @include transition(opacity .3s linear);

                    &:hover {
                        @include opacity(1);
                    }

                    .slider-img {
                        margin: 0 auto;

                        /*Safari6.0-9.0*/
                        -webkit-filter: grayscale(100%);
                        filter: grayscale(100%);
                        &:hover //;

                            {
                            -webkit-filter: grayscale(0%);
                            filter: grayscale(0%);
                        }

                    }
                }

            }
        }

        &.dm_horeca {
            border-top: 1px solid color(border_light);
            padding-bottom: 0;

            .slider-title {
                text-transform: none;
            }

            .hrc-container {
                padding-left: 0;
                padding-right: 0;
            }

            .slider_wrapper {
                .slick-track {
                    margin: 0 auto;
                }

                .slider_item {
                    position: relative;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .slider-content {
                    position: absolute;
                    top: 50%;
                    // left: calc(50% - 250px / 2);

                    @media(max-width: 1200px) and (min-width: 662px) {
                        left: calc(50% - 290px / 2);
                    }


                    .hrc-btn {
                        background: #000;
                        color: color(white-clr);
                        font-family: font(sub);
                        font-size: 18px;
                        line-height: 24px;
                        padding: 10px 15px;
                        @include border-radius(25px);

                        @media(max-width: $media_md) {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}