.main-footer {
    color: color(footer_color);
    background: color(footer_bg);

    .footer-section {
        padding-top: 25px;
        padding-bottom: 25px;

        &:last-of-type {
            padding-bottom: 0;
        }

        &.footer-main {
            padding-top: 60px;
            padding-bottom: 60px;

            .footer-brand {
                .footer-logo {
                    margin-bottom: 60px;
                }

                a {
                    
                    font-size: 26px;
                    font-weight: 600;
                    color: color(footer_color);
                    text-transform: lowercase;
                    @include transition(all .3s linear);

                    .company-accent {
                        color: color(primary);
                    }

                    &:hover {
                        text-decoration: none;
                        color: color(primary);

                        .company-accent {
                            color: color(footer_color);
                        }
                    }
                }

            }

            .footer-contact {
                

                @media(max-width: $media_lg) {
                    margin-bottom: 35px;
                }

                li {
                    font-family: font(sub);
                    font-weight: 300;
                    font-size: 14px;
                    @include transition(all .3s linear);
                    margin-bottom: 35px;
                    padding-left: 30px;
                    color: color(white-clr);
                    &:last-of-type{
                        margin-bottom: 0px;
                    }
                    span {
                        font-weight: 400;
                        display: block;
                        text-transform: uppercase;
                        color:color(footer_color);
                        &.company-name {
                            font-weight: 700;
                        }
                    }

                    a {
                        color: color(white-clr);

                        &:hover {
                            color: color(primary);
                            text-decoration: none;
                        }
                    }

                }
            }

            .footer-nav {
                &.column-2 {
                    -webkit-columns: 2;
                    -moz-columns: 2;
                    columns: 2;

                    @media (max-width: $media_md) {
                        -webkit-columns: 1;
                        -moz-columns: 1;
                        columns: 1;
                        margin: 0 auto;
                    }
                }

                ul {
                    font-size: 14px;

                    column-gap: 2em;
                    column-width: auto;

                    @media(max-width: $media_md) {
                        text-align: center;
                    }

                    li {
                        font-family: font(sub);
                        font-weight: 700;
                        text-transform: uppercase;
                        font-size: 14px;
                        margin-bottom: 20px;

                        @media(max-width: $media_md) {
                            display: block;
                        }

                        a {
                            display: block;
                            padding: 1px;
                            @include transition(all .3s linear);

                            &:hover {
                                text-decoration: none;
                                color: color(primary);
                            }
                        }
                    }
                }
            }

            .footer-follow {
                .social-title {
                    @media(min-width: $media_lg) {
                        padding-left: 30%;
                    }
                }
            }

            .footer-share {
                margin-top: 30px;
            }

            .footer-social {


                .social-title {
                    font-family: font(sub);
                    font-weight: 700;
                    font-size: 14px;
                    text-align: right;
                    display: block;

                    @media (max-width: $media_lg) {
                        text-align: center;
                    }
                }

                .social-icons {
                    text-align: right;
                    padding-top: 2vh;
                    padding-bottom: 2vh;

                    @media (max-width: $media_lg) {
                        text-align: center;
                    }

                    .social-icon {
                        background: color(white-clr);
                        text-align: center;
                        border-radius: $border_radius;
                        @include transition(all .3s linear);
                        width: 35px;
                        height: 35px;
                        display: inline-block;
                        margin: 0 2.5px;

                        &:hover {
                            background: color(primary);
                            text-decoration: none;

                            i {
                                color: color(white-clr);
                            }
                        }

                        i {
                            font-size: 18px;
                            line-height: 35px;
                            color: color(footer_social_icons_color);
                            transition: all .3s linear;
                        }
                    }
                }

                .social-media {
                    padding-top: 2vh;
                    padding-bottom: 3vh;
                    text-align: right;

                    @media(max-width: $media_lg) {
                        text-align: center;
                    }

                    .at-share-btn {
                        background-color: transparent !important;
                        border: 1px solid color(border_dark);
                        @include transition(all .3s linear);
                        border-radius: $border_radius;

                        .at-icon-wrapper {
                            line-height: 35px !important;
                            width: 35px !important;
                            height: 35px !important;
                            text-align: center;
                        }

                        .at-icon {
                            line-height: 35px !important;
                            width: 20px !important;
                            height: 35px !important;
                        }

                        &:hover {
                            color: color(primary);
                            border-color: color(primary);

                        }

                        .at-icon:hover {
                            fill: color(primary) !important;
                        }
                    }
                }
            }
        }

        &.footer-bottom {
            padding-bottom: 20px;
            padding-top: 20px;
            color: color(footer_color_accent);
            background: color(footer_bg_accent);
            border-top: 1px solid color(footer_border);
            @media(max-width: $media_md) {
                .col-12 {
                    padding-bottom: 10px;
                }
            }
            .footer-list{
                li{
                    font-family: fonto(roboto);
                    font-size: 13px;
                    &:not(:last-of-type){
                        border-right: 1px solid #454748;
                        padding-right: 10px;
                    }
                }
            }
            .dimedia-logo {
                max-height: 21px;
                max-width: auto;
            }

        }
    }





    .footer-copyright {
        text-align: center;
        margin-top: 1rem;

        span {
            font-family: font(sub);
            font-size: 13px;
            font-weight: 300;
        }
    }

    // old check if needed






    // .footer-share {
    //     .social-media {
    //         padding-top: 2vh;
    //         padding-bottom: 3vh;

    //         .at-share-btn {
    //             background-color: transparent !important;
    //             border: 2px solid color(white-clr);

    //             &:hover {
    //                 color: color(primary);
    //                 border-color: color(primary);

    //             }

    //             .at-icon:hover {
    //                 fill: color(primary) !important;
    //             }
    //         }
    //     }
    // }

    .footer-partners {
        padding: 40px 0 20px;
        text-align: center;
        background-color: color(white-clr);
        color: color(text-dark);
        padding-bottom: 1vh;
        padding-top: 2vh;

        .footer-logos {
            text-align: center;
            height: 100px;

            .htz-hrvatska {
                text-align: center;
                height: 48px;
                width: auto;
            }

            .tz-split {
                text-align: center;
                max-height: 80px;
                width: auto;
            }

            .hotels {
                padding-top: 20px;
                padding-bottom: 20px;

            }
        }

        ul {
            li {
                display: inline-block;
                margin-left: -4px;
                @include transition(opacity 0.15s ease-in-out);

                &:hover {
                    @include opacity(0.6);
                }
            }
        }
    }


    .social-links {
        margin-top: 7vh;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
    }

    .sitemap {
        margin: 2rem auto;

        a {
            border-bottom: 1px dotted #fff;
            font-weight: 300;
        }
    }

}



@media (max-width: $media_lg) {
    #main-footer {
        .footer-navigation {
            display: none;
        }

        .social-links {
            padding-top: 25vh;
        }
    }
}

@media (max-width: $media_sm) {
    #main-footer {
        .social-links {
            padding-top: 15vh;
        }
    }
}

// STARTUP CODE
/* FOOTER VARS */
$footer_bg: #36434d;
$footer_color_main: #b7b7b7;

/* FOOTER STYLES */
.accent {
    color: color(footer_color_accent) !important;
    font-weight: 700;
}

.ft-title {
    @extend .accent;
    font-family: font(main);
    font-size: 1.6rem;
    text-transform: uppercase;
}

.footer {
    font-size: 1.4rem;
    color: $footer_color_main;
    padding: 50px 0;

    h4 {
        margin-bottom: 15px;
        line-height: 1;
        @extend .ft-title;
    }

    i {
        color: color(footer_color_accent);
        margin-right: 10px;
    }

    address {
        margin-bottom: 0;

        i {
            font-size: 16px;

            &.fa-skype {
                font-size: 15px;
            }

            &.fa-envelope {
                font-size: 14px;
            }

            &.fa-mobile {
                font-size: 20px;
                margin-right: 5px;
                position: relative;
                right: 2px;
            }
        }
    }
}

.footer-info {
    background-color: $footer_bg;
    font-weight: 300;
}

.footer-links {
    background-color: darken($footer_bg, 4%);

    .ft-title {
        margin: 0;
        line-height: 35px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        a {
            &:hover {
                text-decoration: none;
                opacity: 0.75;
            }
        }
    }

    ul {
        li {
            line-height: 35px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            /*             background-color: darken($footer_bg, 4%);
            @include transition(all 0.2s ease-in-out); */
            a {
                display: block;

                &:hover {
                    text-decoration: none;
                    opacity: 0.75;
                }
            }

            /*             &:hover {
                background-color: darken($footer_bg, 8%);
                @include transition(opacity 0.7s);
            } */
        }
    }
}


// .footer-bottom {
//     // background-color: darken($footer_bg, 12%);
//     padding: 40px 0;
//     font-size: 1.4rem;
//     font-weight: 300;
//     color: #898989;

//     ul {
//         margin: 0;
//         display: flex;
//         justify-content: space-between;

//         li {
//             span {
//                 color: #cd0000;
//             }

//             .dm-link {
//                 &:hover {
//                     color: #cd0000;
//                 }
//             }
//         }
//     }
// }

/* NEWSLETTER */
.dmn-input-group {
    max-height: 42px;

    .dmn-form-control {
        padding: 0.95rem 1.5rem;
        background-color: rgba(255, 255, 255, 0.02);
        border-radius: 0;
        border-color: color(border_light);
        color: #d6d6d6;
        font-size: 1.4rem;
        max-width: 220px;
        overflow: hidden;

        &:focus {
            box-shadow: 0 0 0 2px fade(color(secondary), 20%);
        }
    }

    button {
        width: 55px;
        border: 1px solid color(secondary);
        background-color: color(secondary);
        border-radius: 0;
        font-size: 1.8rem;
        padding: 0.65rem 0.75rem;
        justify-content: center;
        @include transition(background 0.15s ease-in-out);

        i {
            margin: 0;
        }

        &:hover {
            background-color: lighten(color(secondary), 2%);
        }
    }
}

// /* SOCIAL SHARE POPUP/LINKS */
.social-container {
    position: absolute;
    bottom: 0;
}

.social-link {
    border-right: 1px solid color(border_light);
    text-transform: uppercase;
    font-weight: 700;

    &:last-of-type {
        border: 0;
    }

    &:not(.icon-only) {
        padding-right: 20px;
        color: #ffffff;
        line-height: 32px;
        display: inline-block;
    }

    &.icon-only {
        width: 50px;
        line-height: 32px;
        text-align: center;
        display: inline-block;
        margin-left: -4px;

        i {
            font-size: 1.6rem;
            margin: 0;
            @include transition(color 0.3s ease-in-out);

            &.fa-facebook,
            &.fa-google-plus {
                font-size: 1.5rem;
            }
        }
    }

    &:hover {
        text-decoration: none;
        color: color(text-dark);

        i {
            color: color(text-dark);
        }
    }
}

/* SOCIAL SHARE POPUP */
.social-popup {
    display: none;
    position: relative;
    top: -10%;
    //background-color: darken($footer_bg, 4%);
    //max-width: 115px;
    //padding: 5px;

    &:after {
        content: "";
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-top-color: darken(color(primary), 20%);
        position: absolute;
        bottom: -14px;
        left: 7px;
        margin-left: -7px;
        @include opacity(0.3);
        @include rotate(-40deg);
        @include transition(all 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55));
    }

    a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        margin-left: -4px;
        background-color: darken(color(primary), 20%);
        text-align: center;
        -webkit-font-smoothing: antialiased;
        @include transition(all 0.15s ease-in-out);
        @include translate3d(0, 0, 0);

        &:first-of-type {
            margin-left: 0;
        }

        i {
            margin: 0;
            -webkit-font-smoothing: antialiased;
            @include translate3d(0, 0, 0);

            &.fa-envelope {
                font-size: 1.3rem;
            }
        }

        &:hover {
            @include opacity(0.65);
        }
    }

    &.active {
        display: block;

        a {
            -webkit-animation-name: socialPopup;
            animation-name: socialPopup;
            -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
            //@include loop-list(0.25s, 4);
        }
    }

    &.on {
        &:after {
            left: 25%;
            @include opacity(1);
            @include rotate(0);
        }
    }
}

/* TRANSITION/DELAY LOOP */
/* LESS
@mixin loop-list($duration, $n, $i: 1) when ($i <= $n) {
    &:nth-of-type(${i}) {
        -webkit-animation-duration: ($duration*$i); // code for each iteration
        animation-duration: ($duration*$i); // code for each iteration
    }
    .loop-list($duration, $n, ($i + 1)); // next iteration
}*/
/* SASS */
@for $i from 1 through 4 {
    .social-popup.active a:nth-of-type(#{$i}) {
        -webkit-animation-duration: (0.25s*$i); // code for each iteration
        animation-duration: (0.25s*$i); // code for each iteration
    }
}

/* KEYFRAMES */
@-webkit-keyframes socialPopup {
    0% {
        opacity: 0;
        transform: rotate(-30deg) translateY(-50px) rotateX(-90deg);
    }

    50% {
        opacity: 1;
        transform: rotate(10deg) translateY(5px) rotateX(2deg);
    }

    100% {
        transform: rotate(0) translateY(0) rotateX(0);
    }
}

@keyframes socialPopup {
    0% {
        opacity: 0;
        transform: rotate(-30deg) translateY(-50px) rotateX(-90deg);
    }

    50% {
        opacity: 1;
        transform: rotate(10deg) translateY(5px) rotateX(2deg);
    }

    100% {
        transform: rotate(0) translateY(0) rotateX(0);
    }
}

/* MEDIA QUERIES */
@media (max-width: $media_xl) {
    .footer-partners {
        p {
            img {
                margin: 10px auto 30px;
            }
        }
    }
}

@media (max-width: $media_lg) {
    .footer-links {
        display: none;
    }

    .footer-partners {
        p {
            img {
                display: block;
            }
        }
    }

    .footer-bottom {
        ul {
            flex-direction: column;
            text-align: center;
        }
    }
}

@media (max-width: $media_md) {
    .dmn-input-group {
        max-height: 36px;

        .dmn-form-control {
            padding: 0.95rem 1rem;
            font-size: 1.3rem;
        }

        button {
            width: 40px;
            padding: 0.35rem 0.75rem;
        }
    }

    .social-link {
        &:first-of-type {
            padding-right: 15px;
        }

        &.icon-only {
            width: 40px;
            line-height: 28px;
        }
    }
}

@media (max-width: $media_md) {
    .footer-logos {
        height: auto !important;

        li {
            padding-top: 15px;
        }
    }

    .footer-copyright {
        .row {
            div {
                text-align: center !important;
                padding-top: 10px;
            }
        }
    }
}

// @media (max-width: $media_sm) {
//     #main-footer {
//         .footer-share {
//             .social-media {
//                 .at-share-btn {

//                     .at-icon-wrapper {
//                         line-height: 25px !important;
//                         height: 25px !important;
//                         width: 25px !important;

//                         svg {
//                             width: 25px !important;
//                             height: 25px !important;
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     .social-container {
//         position: relative;
//         margin-top: 45px;
//     }
// }