.section {
    &.slider_v3 {

        .slider-separator {
            margin-bottom: 60px;
        }

        .slider_item {
            margin-bottom: 25px;

            // &.slider-item-left {
            //     .slider-img {
            //         float: left;
            //         margin-right: 20px;
            //     }
            // }

            // &.slider-item-right {
            //     .slider-img {
            //         float: right;
            //         margin-left: 20px;
            //     }
            // }

            .brand-img-wrapper {
                margin-bottom: 30px;
            }

            .item-content {
                .slider-item-link {
                    color: color(primary);

                    &:hover {
                        color: color(text-dark);
                    }
                }

                .brand-title {
                    margin-bottom: 25px !important;
                }
            }
        }

        .services_init {
            margin-top: 30px;

            @media(min-width:992px) {
                .col-lg-5 {
                    flex: 0 0 37.66667%;
                    max-width: 37.66667%;
                    padding-left: 0;
                    padding-right: 0;
                }

                .col-lg-7 {
                    flex: 0 0 62.33333%;
                    max-width: 62.33333%;
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .slider_item {
                margin-bottom: 30px;

                &:nth-child(odd) {
                    .order-last {
                        padding-left: 10px;
                    }
                }

                &:nth-child(even) {
                    .order-first {
                        -ms-flex-order: 13;
                        order: 13;
                    }

                    .order-last {
                        -ms-flex-order: -1;
                        order: -1;
                        padding-right: 10px;

                        .item-content {
                            @media(min-width: $media_md) {
                                .item-title {
                                    text-align: right;
                                }
                            }
                        }
                    }

                    @media (max-width: $media_md) {
                        .order-first {
                            -ms-flex-order: -1;
                            order: -1;
                        }

                        .order-last {
                            -ms-flex-order: 13;
                            order: 13;
                        }
                    }
                }

                .item-content {
                    .item-title {
                        font-family: font(main);
                        font-size: 28px;
                        font-weight: 500;
                        text-transform: none;
                        line-height: 28px;
                        color: color(primary);
                        margin-bottom: 35px;
                        display: block;
                    }

                    .item-desc {
                        margin-bottom: 10px;
                        display: block;

                        p {
                            font-size: 15px;
                            font-family: font(sub);
                            font-weight: 300;
                            line-height: 24px;
                            color: color(text-dark);
                            padding-bottom: 20px;
                        }
                    }

                    .slider-item-link {

                        color: color(primary);
                        font-size: 14px;
                        font-weight: 700;
                        font-family: font(sub);

                        i {
                            font-size: 16px;
                            color: color(primary);
                            margin-right: 5px;
                        }

                        &:hover {
                            text-decoration: none;

                            i {
                                -webkit-animation: leftToRight 1s ease-out infinite;
                                animation: leftToRight 1s ease-out infinite;
                            }
                        }

                    }
                }

                .slider-img {
                    float: none !important;
                    margin-right: 0;
                }
            }
        }

        .brands_init {
            .slider_item {

                &:nth-of-type(odd) {
                    @media (min-width: $media_md) {
                        text-align: right;
                        border-right: 1px solid color(border_lighter);
                        padding-right: 30px;
                        padding-left: 0px;

                        .brand-img-wrapper {
                            text-align: right;
                        }
                    }
                }

                &:nth-of-type(even) {
                    @media (min-width: $media_md) {
                        padding-right: 0px;
                        padding-left: 30px;

                    }
                }

                .item-content {
                    .item-title {
                        font-size: 21px;
                        font-weight: 500;
                    }

                    .item-desc {
                        p {
                            padding-bottom: 2rem;
                        }
                    }
                }

            }
        }


    }

    &.section-partner_network {
        padding-top: 100px;
        padding-bottom: 100px;

        .section-title {
            color: color(text-lighter);
        }

        hr.slider-separator {
            background: color(white-clr);
        }

        .partner_network_init {

            .slider_item {
                &:nth-of-type(odd) {
                    @media (min-width: $media_md) {
                        text-align: right;
                        border-right: 1px solid color(border_darker);
                        padding-left: 0;
                        padding-right: 30px;
                    }
                }

                &:nth-of-type(even) {
                    @media (min-width: $media_md) {
                        padding-right: 0;
                        padding-left: 30px;
                    }
                }

                .item-content {
                    .item-title {
                        color: color(text-lighter);
                        text-transform: none;
                        font-size: 21px;
                    }

                    .item-desc {
                        color: color(text-lighter);

                        p {
                            color: color(text-lighter);
                        }
                    }

                }

                .partners-img-wrapper {

                    img {
                        width: 100%;
                        height: auto;

                    }

                    @media(max-width: $media_md) {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}